import {useEffect} from "react";
import {Auth} from "aws-amplify";

export function useEffectAsync(effect, inputs) {
    useEffect(() => {
        effect();
    }, inputs);
}

const LOCAL_STORAGE_VERTX_DATA_KEY = "vertx_data";

function getApiKeyAndBucketFromLocalStorage() {
    const userData = localStorage.getItem(LOCAL_STORAGE_VERTX_DATA_KEY);
    let apiKey, buckets
    if (userData) {
        const parsed = JSON.parse(userData)
        apiKey = parsed.token;
        buckets = parsed.buckets;
    }
    return {apiKey, buckets}
}

export async function fetchApiKeyIfMissing(apiBase, setApikey, setBuckets) {
    const {apiKey, buckets} = getApiKeyAndBucketFromLocalStorage();
    if (setApikey) {
        setApikey(apiKey);
    }
    if (setBuckets) {
        setBuckets(buckets);
    }
    //We want to update the data from the server
    await getFetchApiKeyPromise(apiBase, setApikey, setBuckets)();
}

export function getFetchApiKeyPromise(apiBase, setApikey, setBuckets) {
    return async () => {
        console.log(`fetching api_key from ${apiBase} `)
        fetch(
            `${apiBase}/dashboard/apikey`,
            {
                method: 'GET',
                headers: new Headers({
                    'Authorization': (await Auth.currentSession()).getIdToken().getJwtToken(),
                    'Content-Type': 'application/json'
                }),
            }
        )
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Something went wrong');
                }
            })
            .then(json => {
                localStorage.setItem(LOCAL_STORAGE_VERTX_DATA_KEY, JSON.stringify(json));
                if (setApikey) {
                    setApikey(json.token);
                }
                if (setBuckets) {
                    setBuckets(json.buckets);
                }
            })
            .catch((error) => {
                console.log(error)
            });
    }
}

export async function fetchYamlSpec(apiBase, spec) {
    const { apiKey } = getApiKeyAndBucketFromLocalStorage();
    fetch(
        `${apiBase}/console/openapi/${spec}.json`,
        {
            method: 'GET',
        }
    )
        .then(response => {
            if (response.ok) {
                return response.text();
            } else {
                throw new Error('Something went wrong');
            }
        })
        .then(specJson => {
            let docEl = document.getElementById("playground");
            specJson = specJson.replace(/\${YOUR_VERTX_API_KEY}/g, apiKey).replaceAll('https://api.vertx.ai', apiBase);
            let objSpec = JSON.parse(specJson);
            console.log(objSpec)
            docEl.loadSpec(objSpec);
        })
        .catch((error) => {
            console.log(error)
        });
}
