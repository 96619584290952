import React from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import { Layout, Menu } from "antd";

import 'react-sticky-header/styles.css';
import StickyHeader from 'react-sticky-header';

import { Auth } from 'aws-amplify';
import { Button } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

async function signOut() {
    try {
        await Auth.signOut();
    } catch (error) {
        console.log('error signing out: ', error);
    }
}

const Header = ({ location }) => (
    <StickyHeader
        header={

            <Layout.Header
                style={{
                    padding: "0 32px"
                }}
            >
                <div
                    style={{
                        float: "left"
                    }}
                >
                    <h2
                        style={{
                            color: "#fff",
                            margin: 0,
                            marginRight: "1em",
                            display: "inline",
                            width: 100,
                            lineHeight: "54px"
                        }}
                    >
                        Vertx Portal
                    </h2>
                </div>
                <div
                    style={{
                        float: "left"
                    }}
                >
                <Menu
                    theme="dark"
                    mode="horizontal"
                    selectedKeys={[location.pathname]}
                    style={{
                        lineHeight: "64px"
                    }}
                >
                    <Menu.Item key="/">
                        <Link to="/">Settings</Link>
                    </Menu.Item>
                    <Menu.Item key="/playground">
                        <Link to="/playground">Playground</Link>
                    </Menu.Item>
                    <Menu.Item key="/dashboard">
                        <Link to="/dashboard">Dashboard</Link>
                    </Menu.Item>
                    <Menu.Item key="/documentation">
                        <Link to="/documentation" target="_blank" rel="noopener noreferrer">Documentation</Link>
                    </Menu.Item>
                    <Menu.Item key="/api_reference">
                        <Link to="/api_reference" target="_blank" rel="noopener noreferrer">API reference</Link>
                    </Menu.Item>

                    {/*<Menu.Item key="/explore">*/}
                    {/*    <Link to="/explore">Explore</Link>*/}
                    {/*</Menu.Item>*/}

                    {/*<Menu.Item key="/swaggerui">*/}
                    {/*    <Link to="/swaggerui">SwaggerUI</Link>*/}
                    {/*</Menu.Item>*/}

                </Menu>
                </div>
                <div
                    style={{
                        float: "right",
                    }}
                >
                    <Button variation="primary" onClick={signOut} >Sign out</Button>
                </div>
            </Layout.Header>
        }
        headerOnly={true}
        />
);

export default withRouter(Header);
