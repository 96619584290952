import React from "react";
import './body.css';
import 'antd/dist/antd.css';
import '@ant-design/compatible';
import { withRouter } from "react-router";
import { Layout } from "antd";
import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloProvider as ApolloHooksProvider } from "@apollo/react-hooks";
import { ApolloProvider } from "react-apollo";
import AWSAppSyncClient, { AUTH_TYPE } from "aws-appsync";
import { Rehydrated } from "aws-appsync-react";
import cubejs from "@cubejs-client/core";
import { CubeProvider } from "@cubejs-client/react";
import {Authenticator, Button, Heading, Image, Text, useAuthenticator, useTheme, View} from '@aws-amplify/ui-react';

import QRCode from 'qrcode.react';

import '@aws-amplify/ui-react/styles.css';

import { AuthState, onAuthUIStateChange } from "@aws-amplify/ui-components";

import Amplify, { Auth, Hub } from 'aws-amplify';

import Header from './components/Header';
import aws_exports from './aws-exports';
import {
    fetchApiKeyIfMissing,
} from "./common/helpers";

export const REACT_APP_VERTX_API_BASE  = process.env.REACT_APP_VERTX_API_BASE;
export const REACT_APP_CUBEJS_API_URL  = process.env.REACT_APP_CUBEJS_API_URL;

const cubejsApi = cubejs(
    async () => (await Auth.currentSession()).getIdToken().getJwtToken(),
    { apiUrl: `${REACT_APP_CUBEJS_API_URL}/cubejs-api/v1`}
);

Amplify.configure(aws_exports);

const client = new AWSAppSyncClient(
 {
   disableOffline: true,
   url: aws_exports.aws_appsync_graphqlEndpoint,
   region: aws_exports.aws_appsync_region,
   auth: {
     type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
     jwtToken: async () => (await Auth.currentSession()).getIdToken().getJwtToken()
   },
 },
 { cache: new InMemoryCache() }
);

Hub.listen('auth', (data) => {
  if (data.payload.event === 'signOut') {
    client.resetStore();

    // Clean up vertx_token from localstorage
    localStorage.clear();

    // Needed to trigger loading dashboards. If not refreshed, dashboards are
    // stuck in loading state forever after next login
      // TODO: remove it, seems to be working fine with the most recent libs
    // window.location.reload();
  }

  if (data.payload.event === 'signIn') {
    fetchApiKeyIfMissing(REACT_APP_VERTX_API_BASE, null, null);
  }
});


// const AppLayout = ({ location, children }) => {
//     return (
//         <Layout style={{ height: "100%"}}>
//             <Header location={location} />
//             <Layout.Content>{children}</Layout.Content>
//         </Layout>
//     );
// }

// const AuthStateApp = withRouter(({ location, children }) => {
//     const [authState, setAuthState] = React.useState();
//     const [user, setUser] = React.useState();
//     // const [captcha, setCaptcha] = React.useState();
//     const REACT_APP_VERTX_API_BASE  = process.env.REACT_APP_VERTX_API_BASE;
//
//
//     React.useEffect(() => {
//         return onAuthUIStateChange((nextAuthState, authData) => {
//             setAuthState(nextAuthState);
//             setUser(authData);
//             if (authState === AuthState.SignedIn) {
//                 fetchApiKeyIfMissing(REACT_APP_VERTX_API_BASE, null, null);
//             }
//         });
//     }, []);
//
//     return authState === AuthState.SignedIn && user ? (
//         <div>
//             {/*Main Application*/}
//             <CubeProvider cubejsApi={cubejsApi}>
//                 <ApolloProvider client={client}>
//                     <ApolloHooksProvider client={client}>
//                         <Rehydrated>
//                             <AppLayout location={location}>{children}</AppLayout>
//                         </Rehydrated>
//                     </ApolloHooksProvider>
//                 </ApolloProvider>
//             </CubeProvider>
//         </div>
//     ) : (
//         <div>
//         {/*<ReCAPTCHA*/}
//         {/*    sitekey="6LdwX8cbAAAAANdsVrYlwtdHTwhInY0ou1ObH3UJg"*/}
//         {/*    onChange={setCaptcha}*/}
//         {/*/>*/}
//         <AmplifyAuthenticator>
//             <AmplifySignUp
//                 headerText="Create Vertx account"
//                 slot="sign-up"
//                 usernameAlias="email"
//                 formFields={[
//                     {
//                         type: "email",
//                         // placeholder: "Custom email placeholder",
//                         inputProps: { required: true, autocomplete: "username" },
//                         required: true,
//                     },
//                     {
//                         type: "password",
//                         // placeholder: "vEry$tr0ngP@ssw0rD",
//                         inputProps: { required: true, autocomplete: "new-password" },
//                         required: true,
//                     },
//                     {
//                         type: "phone_number",
//                         // label: "Phone number ",
//                         // placeholder: "Custom phone placeholder",
//                         // inputProps: { required: true, autocomplete: "new-password" },
//                         required: true,
//                     },
//                 ]}
//             />
//             <AmplifySignIn
//                 headerText="Sign in to your Vertx account"
//                 slot="sign-in"
//                 usernameAlias="email"
//             />
//             <AmplifyTotpSetup
//                 headerText="Enable two-step authentication"
//                 signInText="Download the free Google Authenticator app, add a new account, and then scan this barcode to set up your account"
//                 slot="totp-setup"
//                 {...{user}}
//             />
//         </AmplifyAuthenticator>
//         </div>
//     );
// });
//
// export default AuthStateApp;

// const MFATypeOptions = {
//     SMS: true,
//     TOTP: true,
//     Optional: false,
// };
//
// const App = withRouter(({ location, children }) => (
//     <div>
//         <AmplifyAuthenticator>
//             <AmplifySelectMfaType MFATypes={MFATypeOptions}/>
//             <AmplifySignUp
//                 headerText="Create Vertx account"
//                 slot="sign-up"
//                 usernameAlias="email"
//                 formFields={[
//                     {
//                         type: "email",
//                         // placeholder: "Custom email placeholder",
//                         inputProps: { required: true, autocomplete: "username" },
//                         required: true,
//                     },
//                     {
//                         type: "password",
//                         // placeholder: "vEry$tr0ngP@ssw0rD",
//                         inputProps: { required: true, autocomplete: "new-password" },
//                         required: true,
//                     },
//                     {
//                         type: "phone_number",
//                         label: "Phone number",
//                         // placeholder: "Custom phone placeholder",
//                         // inputProps: { required: true, autocomplete: "new-password" },
//                         required: true,
//                     },
//                 ]}
//             />
//             <AmplifyConfirmSignUp
//                 headerText="Confirm email address"
//
//             />
//             <AmplifySignIn
//                 headerText="Sign in to your Vertx account"
//                 slot="sign-in"
//                 usernameAlias="email"
//             />
//             <AmplifyTotpSetup
//                 headerText="My Custom TOTP Setup Text"
//                 slot="totp-setup"
//             ></AmplifyTotpSetup>
//             {/*Main Application*/}
//             <CubeProvider cubejsApi={cubejsApi}>
//                 <ApolloProvider client={client}>
//                     <ApolloHooksProvider client={client}>
//                         <Rehydrated>
//                             <AppLayout location={location}>{children}</AppLayout>
//                         </Rehydrated>
//                     </ApolloHooksProvider>
//                 </ApolloProvider>
//             </CubeProvider>
//         </AmplifyAuthenticator>
//     </div>
// ));
//
// //TODO max : figure out how to add custom header {headerText: "Sign in to your Vertx Portal account"}
// export default App;


const AppLayout = ({ location, children }) => {
    return (
        <Layout style={{ height: "100%"}}>
            <Header location={location} />
            <Layout.Content>{children}</Layout.Content>
        </Layout>
    );
}

const components = {
    Header() {
        const { tokens } = useTheme();

        return (
            <View textAlign="center" padding={tokens.space.large}>
                <Image
                    alt="Vertx logo"
                    width="50%"
                    src="https://www.vertx.ai/logo/logo_black.png"
                />
            </View>
        );
    },

    Footer() {
        const { tokens } = useTheme();

        return (
            <View textAlign="center" padding={tokens.space.large}>
                <Text color={tokens.colors.neutral[80]}>
                    &copy; 2022 Vertx Technologies, Inc. All Rights Reserved
                </Text>
            </View>
        );
    },

    // SignIn: {
    //     Header() {
    //         const { tokens } = useTheme();
    //
    //         return (
    //             <Heading
    //                 padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
    //                 level={3}
    //             >
    //                 Sign in to your account
    //             </Heading>
    //         );
    //     },
    //     Footer() {
    //         const { toResetPassword } = useAuthenticator();
    //
    //         return (
    //             <View textAlign="center">
    //                 <Button
    //                     fontWeight="normal"
    //                     onClick={toResetPassword}
    //                     size="small"
    //                     variation="link"
    //                 >
    //                     Reset Password
    //                 </Button>
    //             </View>
    //         );
    //     },
    // },
    //
    // SignUp: {
    //     Header() {
    //         const { tokens } = useTheme();
    //
    //         return (
    //             <Heading
    //                 padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
    //                 level={3}
    //             >
    //                 Create a new account
    //             </Heading>
    //         );
    //     },
    //     Footer() {
    //         const { toSignIn } = useAuthenticator();
    //
    //         return (
    //             <View textAlign="center">
    //                 <Button
    //                     fontWeight="normal"
    //                     onClick={toSignIn}
    //                     size="small"
    //                     variation="link"
    //                 >
    //                     Back to Sign In
    //                 </Button>
    //             </View>
    //         );
    //     },
    // },
    // ConfirmSignUp: {
    //     Header() {
    //         const { tokens } = useTheme();
    //         return (
    //             <Heading
    //                 padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
    //                 level={3}
    //             >
    //                 Enter Information:
    //             </Heading>
    //         );
    //     },
    //     Footer() {
    //         return <Text>Footer Information</Text>;
    //     },
    // },
    // SetupTOTP: {
    //     Header() {
    //         const { tokens } = useTheme();
    //         return (
    //             <Heading
    //                 padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
    //                 level={3}
    //             >
    //                 Enter Information:
    //             </Heading>
    //         );
    //     },
    //     Footer() {
    //         return <Text>Footer Information</Text>;
    //     },
    // },
    // ConfirmSignIn: {
    //     Header() {
    //         const { tokens } = useTheme();
    //         return (
    //             <Heading
    //                 padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
    //                 level={3}
    //             >
    //                 Enter Information:
    //             </Heading>
    //         );
    //     },
    //     Footer() {
    //         return <Text>Footer Information</Text>;
    //     },
    // },
    // ResetPassword: {
    //     Header() {
    //         const { tokens } = useTheme();
    //         return (
    //             <Heading
    //                 padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
    //                 level={3}
    //             >
    //                 Enter Information:
    //             </Heading>
    //         );
    //     },
    //     Footer() {
    //         return <Text>Footer Information</Text>;
    //     },
    // },
    // ConfirmResetPassword: {
    //     Header() {
    //         const { tokens } = useTheme();
    //         return (
    //             <Heading
    //                 padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
    //                 level={3}
    //             >
    //                 Enter Information:
    //             </Heading>
    //         );
    //     },
    //     Footer() {
    //         return <Text>Footer Information</Text>;
    //     },
    // },
};

//TODO this does not have any effect
const formFields = {
    setupTOTP: {
        QR: {
            totpIssuer: 'vertx',
            totpUsername: 'vertx',
        },
    },
};

const AuthStateApp = withRouter(({ location, children }) => {
    const [authState, setAuthState] = React.useState();
    const [user, setUser] = React.useState();
    // const [captcha, setCaptcha] = React.useState();
    const REACT_APP_VERTX_API_BASE  = process.env.REACT_APP_VERTX_API_BASE;


    React.useEffect(() => {
        return onAuthUIStateChange((nextAuthState, authData) => {
            setAuthState(nextAuthState);
            setUser(authData);
            if (authState === AuthState.SignedIn) {
                fetchApiKeyIfMissing(REACT_APP_VERTX_API_BASE, null, null);
            }
        });
    }, []);

    return (
        // for customizations https://ui.docs.amplify.aws/react/connected-components/authenticator/customization
        <div>
            <Authenticator formFields={formFields} components={components}>
            <CubeProvider cubejsApi={cubejsApi}>
                <ApolloProvider client={client}>
                    <ApolloHooksProvider client={client}>
                        <Rehydrated>
                            <AppLayout location={location}>{children}</AppLayout>
                        </Rehydrated>
                    </ApolloHooksProvider>
                </ApolloProvider>
            </CubeProvider>
            </Authenticator>
        </div>
    );
});

export default AuthStateApp;