import React from 'react';
import ReactDOM from 'react-dom/client';
import {createBrowserHistory} from "history";
import {BrowserRouter as Router, Route} from 'react-router-dom';
import ExplorePage from './pages/ExplorePage';
import DashboardPage from './pages/DashboardPage';
import DocumentationPage from './pages/DocumentationPage';
import PlaygroundPage from "./pages/PlaygroundPage";
import SwaggerUIPage from "./pages/SwaggerUIPage";

import App from './App';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <Router history={createBrowserHistory}>
        <App>
            <Route key="index" exact path="/" component={DocumentationPage}/>
            <Route key="playground" path="/playground" component={PlaygroundPage}/>
            <Route key="dashboard" path="/dashboard" component={DashboardPage}/>

            <Route key="explore" path="/explore" component={ExplorePage} />
            <Route key="documentation" path="/documentation" component={() => {
                window.location.href = 'https://www.vertx.ai/docs';
                return null;
            }}/>
            <Route key="apireference" path="/api_reference" component={() => {
                window.location.href = 'https://www.vertx.ai/api_reference';
                return null;
            }}/>
            {/*<Route key="swaggerui" path="/swaggerui" component={SwaggerUIPage} />*/}
        </App>
     </Router>, // eslint-disable-next-line no-undef
);