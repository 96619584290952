import React, {useState} from 'react';
import { Spin, Button, Alert } from 'antd';
import { Link } from 'react-router-dom';
import {useMutation, useQuery} from '@apollo/react-hooks';
import { Icon } from '@ant-design/compatible';
import { GET_DASHBOARD_ITEMS } from '../graphql/queries';
import ChartRenderer from '../components/ChartRenderer';
import Dashboard from '../components/Dashboard';
import DashboardItem from '../components/DashboardItem';
import {CREATE_DASHBOARD_ITEM} from "../graphql/mutations";

const deserializeItem = i => ({ ...i,
  layout: JSON.parse(i.layout) || {},
  vizState: JSON.parse(i.vizState)
});

const defaultLayout = i => ({
  x: i.layout.x || 0,
  y: i.layout.y || 0,
  w: i.layout.w || 4,
  h: i.layout.h || 8,
  minW: 4,
  minH: 8
});

const DashboardPage = () => {
  const [completed, setCompleted] = useState(false);

  const {
    loading,
    error,
    data
  } = useQuery(GET_DASHBOARD_ITEMS);

  const [addDashboardItem] = useMutation(CREATE_DASHBOARD_ITEM, {
    refetchQueries: [
      {
        query: GET_DASHBOARD_ITEMS
      }
    ]
  });

  const addDashboards = (finalVizState, finalTitle, finalLayout) => {
    addDashboardItem({
      variables: {
        input: {
          id: null,
          vizState: JSON.stringify(finalVizState),
          name: finalTitle,
          layout: JSON.stringify(finalLayout)
        }
      }
    });
  };

  if (loading) {
    return <Spin />;
  }

  if (error) {
    return <Alert message="Error occured while loading your query" description={error.toString()} type="error" />;
  }

  const dashboardItem = item => <div key={item.id} data-grid={defaultLayout(item)}>
    <DashboardItem key={item.id} itemId={item.id} title={item.name}>
      <ChartRenderer vizState={item.vizState} />
    </DashboardItem>
  </div>;

  const Empty = () => <div style={{
    textAlign: 'center',
    padding: 12
  }}>
    <h2>There are no charts on this dashboard</h2>
    <Link to="/explore">
      <Button type="primary" size="large" icon={<Icon type="plus" />}>
        Add chart
      </Button>
    </Link>
  </div>;

  if (data && !data.listDashboardItems.items.length && !completed) {
    setCompleted(true)
    addDashboards({
          "query": {
            "measures": [
              "VertxRequests.count"
            ],
            "timeDimensions": [
              {
                "dateRange": "from 60 minutes ago to now",
                "granularity": "minute",
                "dimension": "VertxRequests.timestamp"
              }
            ],
            "timezone": Intl.DateTimeFormat().resolvedOptions().timeZone
          },
          "chartType": "line",

        }, "Search requests (last hour)",
        {"x":0,"y":0,"w":8,"h":8}
    );
    addDashboards({
          "query": {
            "measures": [
              "VertxRequests.count"
            ],
            "timeDimensions": [
              {
                "dateRange": "from 23 hours ago to now",
                "granularity": "minute",
                "dimension": "VertxRequests.timestamp"
              }
            ],
            "timezone": Intl.DateTimeFormat().resolvedOptions().timeZone
          },
          "chartType": "line",

        }, "Search Requests (last day)",
        {"x":0,"y":8,"w":8,"h":8}
    );
    addDashboards({
          "query": {
            "measures": [
              "VertxRequests.count"
            ],
            "timeDimensions": [
              {
                "dateRange": "from 168 hours ago to now",
                "granularity": "hour",
                "dimension": "VertxRequests.timestamp"
              }
            ],
            "timezone": Intl.DateTimeFormat().resolvedOptions().timeZone
          },
          "chartType": "line",

        }, "Search requests (last week)",
        {"x":0,"y":16,"w":8,"h":8}
    );

    return <Spin />;
  }

  return !data || data.listDashboardItems.items.length ? (
      <Dashboard dashboardItems={data && data.listDashboardItems.items}>
        {data && data.listDashboardItems.items.map(deserializeItem).map(dashboardItem)}
      </Dashboard>
  ) : <Empty />;
};

export default DashboardPage;