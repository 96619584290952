import React, {useState} from 'react';
import 'rapidoc';
import {
    fetchApiKeyIfMissing, fetchYamlSpec,
    useEffectAsync
} from "../common/helpers";

const SPEC_NAME = 'playground'

function PlaygroundPage() {
    const [apikey, setApikey] = useState("YOUR_VERTX_API_KEY");
    const REACT_APP_VERTX_API_BASE  = process.env.REACT_APP_VERTX_API_BASE;

    useEffectAsync(async () => {
        await fetchApiKeyIfMissing(REACT_APP_VERTX_API_BASE, setApikey, null);
        await fetchYamlSpec(REACT_APP_VERTX_API_BASE, SPEC_NAME);
    }, [])

    return (
        <rapi-doc
            id={SPEC_NAME}
            // spec-url={REACT_APP_VERTX_API_BASE + "/console/openapi/playground.yaml"} // cannot use this since we need to replace YOUR_VERTX_API_KEY with the real key
            // render-style = "view"
            // show-info = 'true'

            show-header='false'
            allow-authentication='false'
            allow-server-selection='false'
            allow-api-list-style-selection='false'
            theme='light'
            // render-style="read"
            // style={{height: "100vh", width: "100%"}}
            // info-description-headings-in-navbar={false}

            server-url={REACT_APP_VERTX_API_BASE + "/v1"}
            default-api-server={REACT_APP_VERTX_API_BASE + "/v1"}
            api-key-name="X-Session-Token"
            api-key-location="header"
            api-key-value={apikey}
        >
        </rapi-doc>
    )
}

export default PlaygroundPage

