import React, {useState} from 'react';
import SwaggerUI from "swagger-ui-react"
import "swagger-ui-react/swagger-ui.css"
import {getFetchApiKeyPromise, useEffectAsync} from "../common/helpers";
import {Auth} from "aws-amplify";

function SwaggerUIPage() {
    const [showMutated, setShowMutated] = useState(true);
    const [specURL, setSpecURL] = useState(REACT_APP_VERTX_API_BASE + "/console/public.yaml");
    const [apikey, setApikey] = useState("YOUR_VERTX_API_KEY");

    const REACT_APP_VERTX_API_BASE  = process.env.REACT_APP_VERTX_API_BASE;

    useEffectAsync(async () => {
        fetch(
            `${REACT_APP_VERTX_API_BASE}/dashboard/apikey`,
            {
                method: 'GET',
                headers: new Headers({
                    'Authorization': (await Auth.currentSession()).getIdToken().getJwtToken(),
                    'Content-Type': 'application/json'
                }),
            }
        ).then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error('Something went wrong');
            }
        })
            .then(json => {setApikey(json.token)})
            .catch((error) => {
                console.log(error)
            });
    }, [])

    function getRequestInterceptor(apikey) {
        return (req) => {
            if (!req.loadSpec) {
                req.headers["X-Session-Token"] = apikey;
            }
            return req;
        }
    }

    return (
        <SwaggerUI
            url={specURL}
            showMutatedRequest={showMutated}
            requestInterceptor={getRequestInterceptor(apikey)}
        />
    )
}

export default SwaggerUIPage
