import React, {useState} from 'react';
import ReactMarkdown from 'react-markdown'
import {
    fetchApiKeyIfMissing,
    useEffectAsync
} from "../common/helpers";
import CodeBlock from "../components/Codeblock";

function DocumentationPage() {
    const [apikey, setApikey] = useState("YOUR_VERTX_API_KEY");
    const [privateBuckets, setPrivateBuckets] = useState("[YOUR_PRIVATE_BUCKETS]");
    const [publicBuckets, setPublicBuckets] = useState(['music', 'movies']);

    const [mdSection, setMdSection] = useState("")

    const REACT_APP_VERTX_API_BASE  = process.env.REACT_APP_VERTX_API_BASE;

    useEffectAsync(async () => {
        fetch("/Settings.md")
            .then(response => response.text())
            .then(text => {
                setMdSection(text)
            });
        fetchApiKeyIfMissing(REACT_APP_VERTX_API_BASE, setApikey, setPrivateBuckets);
    }, [])

    const outerDivStyle= {
        border: 'aqua',
        marginLeft: '10%',
        marginTop: 50,
        marginBottom: 50,
        marginRight: '10%',
    }

    return (
        <div style={outerDivStyle}>
            <div>
                <ReactMarkdown
                    source={mdSection.
                        replace(/YOUR_VERTX_API_KEY/g, apikey).
                        replace(/YOUR_PRIVATE_BUCKETS/g, privateBuckets).
                        replace(/YOUR_PUBLIC_BUCKETS/g, publicBuckets)
                    }
                    renderers={{code: CodeBlock}}
                />
            </div>
        </div>
    );
}

export default DocumentationPage
